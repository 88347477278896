@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.successDiv {
  height: 80vh;
}

.blueprint-div {
  margin-top: 55px;
}

#manage_subscription_link:hover, #reset_thumbnail_text:hover{
  color: #1FBD89;
}

#manage_subscription_link:visited, #reset_thumbnail_text:visited{
  color: #0A7652;
}