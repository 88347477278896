.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiSlider-root {
  color: #fff !important;
}
.carousel-slider{
  height: 100%;
}
.carousel .control-dots{
  text-align: right !important;
}
.carousel .control-dots .dot {
  background: #CBD5E1 !important;
  box-shadow: none !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
   background: #155A9F !important;
}
.carousel .selected {
  animation: fadeOut .75s;
}
.world-detail .carousel .slider-wrapper {
  height: 100%;
}
.world-detail .carousel .slider {
  height: 100%;
}
.world-detail .carousel .control-dots .dot {
  background: rgba(255,255,255, .5) !important;
}

.world-detail .carousel .control-dots .dot.selected, 
.world-detail .carousel .control-dots .dot:hover {
  background: #fff !important
}
.world-detail .carousel .control-dots .dot {
  margin:0;
  margin-right: 10px;
}

 button:focus-visible {
  border:none;
  outline: 0;
  box-shadow: none;
 }

@keyframes fadeOut {
  0% {
    opacity:0;
  }
  40% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
    
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-unClick-btn {
  background-color: #B7EAD9;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.hover-primary-btn {
  color: "#11487f";
} 

.bg-special {
  background-image: linear-gradient(to bottom, #0f172a, #131b2e, #162032, #1a2437, #1e293b);
}

button, input, optgroup, select, textarea {
  accent-color: #144A8f;
}

.one-line-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

}

.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
}


@media (max-width: 1440px) {
  .min-w-\[190px\]{
    min-width: 150px;
  }
}

.chart-container {
  height: 400px; /* Adjust this value as needed */
  width: 100%;
}

th {
  font-weight: 500;
  padding-bottom: 15px;
}

.bgWhite {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background: white;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.analytics-scrollbar {
  display: flex;
}


.analytics-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 190px;
  margin-right: -20px
}

.analytics-scrollbar::-webkit-scrollbar-track {
  background: #E2E8F0;  /* Light grey track */
  height: 190px;
}
.analytics-scrollbar::-webkit-scrollbar-thumb {
  background: #94A3B8;  /* Darker grey handle */
  border-radius: 6px;  /* Rounded edges */
}

.spinner{
  width: 100px;
  height: 100px;
  position: relative;
  margin: 100px auto;
}

.loading-container {
  background-color: #F1F4F7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This will make the container take up the full height of the viewport */
}

.loading-image {
  animation: rotation 2s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.dropdownAnchor {
  background-color: white;
  height: 60px;
  width: 100%;
  text-indent: 20px;
  border-radius: 16px;
  margin-bottom: 20px;
  border: 1px solid #CBD5E1;
  display: flex;
  padding-left: 20px;
}
.dropdownMenu {
  background-color: white;
  z-index: 1000;
  padding:10px;
  border-radius: 16px;
  border: 1px solid #CBD5E1;
  /* other styles... */
}
